import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    brand: {
      100: "#D4E6CD", // nuance de vert clair
      200: "#7FB77E", // nuance de vert
      300: "#5B9C55", // vert algérien foncé
      400: "#8CC28A", // nuance de vert
      500: "#52A852", // la couleur verte principale, évoquant l'Algérie
      600: "#3D7C3D", // vert foncé
      700: "#1E4D1E", // vert très foncé pour un fort contraste
      800: "#66B566", // nuance de vert plus clair pour les éléments distinctifs
      900: "#0C2D0C", // vert très foncé pour les éléments très contrastés
    },
    brandScheme: {
      100: "#D4E6CD",
      200: "#7FB77E",
      300: "#7FB77E",
      400: "#7FB77E",
      500: "#5B9C55",
      600: "#3D7C3D",
      700: "#1E4D1E",
      800: "#0C2D0C",
      900: "#0C2D0C",
    },
    brandTabs: {
      100: "#D4E6CD",
      200: "#5B9C55",
      300: "#5B9C55",
      400: "#5B9C55",
      500: "#5B9C55",
      600: "#3D7C3D",
      700: "#1E4D1E",
      800: "#0C2D0C",
      900: "#0C2D0C",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("orange.300", "navy.900")(props),
        fontFamily: "system-ui, sans-serif",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "system-ui, sans-serif",
      },
    }),
  },
};