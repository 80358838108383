import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  VStack,
  HStack,
  IconButton,
  Box,
  Flex,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdUpload } from 'react-icons/md';
import { AuthContext } from 'contexts/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const AddMemberModal = ({ isOpen, onClose, onSave, selectedMember }) => {
  const initialMemberState = {
    mrName: '',
    mrsName: '',
    socialSecurityNumber: '',
    birthDate: '',
    address: '',
    phone: '',
    email: '',
    nationality: '',
    burialSite: '',
    maritalStatus: '',
    contributionStatus: '',
    children: [{ name: '', birthDate: '' }],
    delegateName: '',
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [delegates, setDelegates] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchDelegates = async () => {
      const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
      if (response.ok) {
        const data = await response.json();
        setDelegates(data.delegateNames);
      }
    };
  
    fetchDelegates();
  }, []);

  const toast = useToast();

  const schema = yup.object().shape({
    mrName: yup.string(),
    mrsName: yup.string(),
    socialSecurityNumber: yup.string(),
    birthDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    phone: yup.string(),
    email: yup.string().email('Email invalide'),
    nationality: yup.string(),
    burialSite: yup.string(),
    maritalStatus: yup.string(),
    contributionStatus: yup.string(),
    children: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        birthDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
      })
    ),
    delegateName: yup.string(),
  });

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialMemberState,
  });

  const children = watch('children');

  useEffect(() => {
    if (selectedMember) {
      reset(selectedMember);
    }
  }, [selectedMember, reset]);

  const handleAddChild = () => {
    setValue('children', [...children, { name: '', birthDate: '' }]);
  };

  const handleRemoveChild = (index) => {
    setValue('children', children.filter((_, i) => i !== index));
  };

  const onSubmit = async (data) => {
    const method = data.id ? 'PUT' : 'POST';
    const adherentUrl = `https://app.falconmarketing.fr:3004/adherents${data.id ? `/${data.id}` : ''}`;
    const contributionAmount = calculateContributionAmount(data.maritalStatus);
  
    try {
      // Sauvegardez d'abord l'adhérent
      const adherentResponse = await fetch(adherentUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          cotisation: contributionAmount, // Ajoutez le montant de la cotisation
          children: undefined, // Retirer les enfants de l'objet membre pour cette requête
        }),
      });
  
      if (!adherentResponse.ok) {
        const errorText = await adherentResponse.text();
        throw new Error(errorText);
      }
  
      const adherentData = await adherentResponse.json();
      const adherentId = data.id ? data.id : adherentData.adherentId; // Utiliser l'ID existant si en mode édition
  
      // Ensuite, ajoutez chaque enfant si l'adhérent est nouveau ou si des enfants sont ajoutés
      if (!data.id || data.children.length > 0) {
        for (let child of data.children) {
          await fetch(`https://app.falconmarketing.fr:3004/children/${adherentId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(child),
          });
        }
      }
  
      // Envoyer les fichiers sélectionnés
      if (selectedFiles.length > 0) {
// Correction :
const formData = new FormData();
formData.append('member', JSON.stringify(data)); // Utiliser `data` au lieu de `memberData`
selectedFiles.forEach((file) => {
  formData.append('files', file); // Ajouter chaque fichier séparément
});
        const fileResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherentId}/files`, {
          method: 'POST',
          body: formData,
        });
  
        if (!fileResponse.ok) {
          const errorText = await fileResponse.text();
          throw new Error(errorText);
        }
      }
  
      toast({
        title: data.id ? 'Membre mis à jour' : 'Membre ajouté',
        description: `Les informations du membre ont été ${data.id ? 'mises à jour' : 'ajoutées'} avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
  
      onClose(); // Fermer la modale
      reset(initialMemberState); // Réinitialiser l'état du formulaire
      setSelectedFiles([]); // Réinitialiser les fichiers sélectionnés
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur s'est produite lors de ${data.id ? "la mise à jour" : "l'ajout"} du membre: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateContributionAmount = (maritalStatus) => {
    const singleStatus = ['single', 'widowed', 'divorcedWithoutChildren'];
    const marriedOrDivorcedWithChildren = ['married', 'divorcedWithChildren'];
  
    if (singleStatus.includes(maritalStatus)) {
      return 55;
    } else if (marriedOrDivorcedWithChildren.includes(maritalStatus)) {
      return 110;
    } else {
      return 0; // Ou un autre montant par défaut si nécessaire
    }
  };
  

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter un nouveau membre</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4} align="stretch">
              <Controller
                name="mrName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Nom et Prénom</FormLabel>
                    <Input {...field} placeholder="Nom et prénom" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="mrsName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Nom et Prénom</FormLabel>
                    <Input {...field} placeholder="Nom et prénom" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="socialSecurityNumber"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Numéro de sécurité sociale</FormLabel>
                    <Input {...field} placeholder="Numéro de sécurité sociale" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="birthDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Date de naissance</FormLabel>
                    <Input 
                      type="date" 
                      {...field} 
                      value={field.value || ''} // Ceci garantit que le champ est vide si la valeur est null
                    />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
              

              <Controller
                name="address"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Adresse</FormLabel>
                    <Input {...field} placeholder="Adresse" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Téléphone</FormLabel>
                    <Input {...field} placeholder="Téléphone" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Email</FormLabel>
                    <Input {...field} placeholder="Email" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="nationality"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Nationalité</FormLabel>
                    <Input {...field} placeholder="Nationalité" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="burialSite"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Lieu d'inhumation</FormLabel>
                    <Input {...field} placeholder="Lieu d'inhumation" />
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="maritalStatus"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Situation familiale</FormLabel>
                    <Select {...field} placeholder="Sélectionnez la situation familiale">
                      <option value="married">Marié</option>
                      <option value="single">Célibataire</option>
                      <option value="divorcedWithChildren">Divorcé avec enfants</option>
                      <option value="divorcedWithoutChildren">Divorcé sans enfants</option>
                      <option value="widowed">Veuf/Veuve</option>
                    </Select>
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="cotisation"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Cotisation</FormLabel>
                    <Input {...field} isReadOnly />
                  </FormControl>
                )}
              />

              <Controller
                name="delegateName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Nom du délégué</FormLabel>
                    <Select {...field} placeholder="Sélectionnez un délégué">
                      {delegates
                        .slice() // Crée une copie du tableau
                        .sort((a, b) => a.localeCompare(b, 'fr', { sensitivity: 'base' })) // Trie les délégués
                        .map((delegate, index) => (
                          <option key={index} value={delegate}>
                            {delegate}
                          </option>
                        ))}
                    </Select>
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />

              <Controller
                name="children"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Enfants</FormLabel>
                    {field.value.map((child, index) => (
                      <HStack key={index} mb={2}>
                        <Controller
                          name={`children[${index}].name`}
                          control={control}
                          render={({ field }) => (
                            <Input {...field} placeholder="Nom de l'enfant" />
                          )}
                        />
                        <Controller
                          name={`children[${index}].birthDate`}
                          control={control}
                          render={({ field }) => (
                            <Input type="date" {...field} />
                          )}
                        />
                        <IconButton
                          aria-label="Supprimer l'enfant"
                          icon={<MdDelete />}
                          onClick={() => handleRemoveChild(index)}
                        />
                      </HStack>
                    ))}
                    <Button leftIcon={<MdAdd />} colorScheme="teal" onClick={handleAddChild}>
                      Ajouter un enfant
                    </Button>
                  </FormControl>
                )}
              />

              <Controller
                name="contributionStatus"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <FormLabel>Statut de cotisation</FormLabel>
                    <Select {...field} placeholder="Sélectionnez le statut de cotisation">
                      <option value="À jour">À jour</option>
                      <option value="En retard">En retard</option>
                      <option value="Radié">Radié</option>
                    </Select>
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
            </VStack>
          </form>
          <Box mt={4}>
            <FormLabel>Pièces justificatives</FormLabel>
            <Flex alignItems="center" mb={2}>
              <IconButton
                aria-label="Ajouter des fichiers"
                icon={<MdUpload />}
                onClick={() => fileInputRef.current.click()}
              />
              <input
                type="file"
                multiple
                accept="application/pdf,image/jpeg,image/png"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </Flex>
            {selectedFiles.length > 0 && (
              <Flex flexWrap="wrap">
                {selectedFiles.map((file, index) => (
                  <Box key={index} mr={2} mb={2}>
                    <Text>{file.name}</Text>
                  </Box>
                ))}
              </Flex>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit(onSubmit)}>
            Sauvegarder
          </Button>
          <Button onClick={onClose}>Annuler</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMemberModal;