import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  Divider,
  Icon,
  Box,
  Heading,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Document, Page, Text as PdfText, View, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer';
import AdherentPDF from './AdherentPDF';
import DecesModal from './DecesModal';


const AdherentDetailsModal = ({ isOpen, onClose, adherent }) => {
  const [children, setChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [isDecesModalOpen, setIsDecesModalOpen] = useState(false);
  


  const generatePDF = async (adherent, children, files) => {
    const blob = await pdf(
      <AdherentPDF adherent={adherent} children={children} files={files} />
    ).toBlob();
    return blob;
  };

  const handleDownload = async () => {
    const pdfBlob = await generatePDF(adherent, children, files);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `adherent_${adherent.id}.pdf`;
    link.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (adherent && adherent.id) {
        try {
          const childrenResponse = await fetch(
            `https://app.falconmarketing.fr:3004/adherents/${adherent.id}/children`
          );
          const childrenData = await childrenResponse.json();
          setChildren(childrenData.children);
          const filesResponse = await fetch(
            `https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`
          );
          const filesData = await filesResponse.json();
          setFiles(filesData.files);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setChildren([]);
          setFiles([]);
        }
      }
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, adherent]);

  if (!adherent) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Détails de l'adhérent</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="start">
            <Box>
              <Text fontWeight="bold">Nom et Prénom:</Text>
              <Text>{adherent.mrName}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Nom et Prénom:</Text>
              <Text>{adherent.mrsName}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Adresse:</Text>
              <Text>{adherent.address}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Téléphone:</Text>
              <Text>{adherent.phone}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Nationalité:</Text>
              <Text>{adherent.nationality}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Numéro de Sécurité Sociale:</Text>
              <Text>{adherent.socialSecurityNumber}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Statut de contribution:</Text>
              <Text>{adherent.contributionStatus}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Nom du délégué:</Text>
              <Text>{adherent.delegateName}</Text>
            </Box>
          </VStack>

          {children.length > 0 && (
            <Box mt={6}>
              <Heading size="sm" mb={2}>
                Enfants
              </Heading>
              <Divider mb={4} />
              <VStack spacing={2} align="start">
                {children.map((child) => (
                  <Text key={child.id}>
                    {child.name} - {child.birthDate}
                  </Text>
                ))}
              </VStack>
            </Box>
          )}

          {files.length > 0 && (
            <Box mt={6}>
              <Heading size="sm" mb={2}>
                Pièces justificatives
              </Heading>
              <Divider mb={4} />
              <VStack spacing={2} align="start">
                {files.map((file, index) => {
                  const fileName = `Fichier ${index + 1}`;
                  return (
                    <HStack
                      key={file.id}
                      as="a"
                      href={`https://app.falconmarketing.fr:3004/${file.filePath}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      spacing={2}
                    >
                      <Icon as={DownloadIcon} boxSize={5} />
                      <Text>{fileName}</Text>
                    </HStack>
                  );
                })}
              </VStack>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>

        <Button colorScheme="blue" mr={3} onClick={handleDownload}>
  Télécharger
</Button>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    
  );
};

export default AdherentDetailsModal;