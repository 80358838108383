import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Adjusted styles for compactness
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 20, // Reduced padding
  },
  heading: {
    fontSize: 10, // Reduced font size
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: 8, // Reduced margin
  },
  label: {
    fontSize: 12, // Reduced font size
    marginBottom: 4, // Reduced margin
  },
  field: {
    fontSize: 14, // Reduced font size
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    marginBottom: 5, // Reduced margin
  },
  table: {
    marginTop: 10, // Reduced margin
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 2, // Reduced margin
  },
  tableCell: {
    fontSize: 10, // Reduced font size
    flex: 1,
    borderWidth: 1,
    borderColor: '#000000',
    padding: 3, // Reduced padding
  },
  checkBoxGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 2,
  },
  checkBoxItem: {
    minWidth: 50, // Adjust width if necessary
    textAlign: 'center',
    margin: 2,
    fontSize: 8, // Reduced font size for the year label
  },
  checkBox: {
    width: 10,
    height: 10,
    marginTop: 2,
    borderWidth: 1,
    borderColor: '#000',
    alignSelf: 'center',
  },
  checkedBox: {
    width: 8,
    height: 8,
    backgroundColor: '#000',
    alignSelf: 'center',
  },
});

const CheckBox = ({ checked }) => (
  <View
    style={{
      width: 8, // Reduced size
      height: 8, // Reduced size
      borderWidth: 1,
      borderColor: '#000',
      marginRight: 5,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {checked && (
      <View
        style={{
          width: 4, // Reduced size
          height: 4, // Reduced size
          backgroundColor: '#000',
        }}
      />
    )}
  </View>
);

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont indexés à partir de 0
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}


// Adjusted CheckBoxTable component for years 2024 to 2044
const CheckBoxTable = ({ lastUpdatedYear }) => {
  const startYear = 2024;
  const endYear = 2043;
  const numYears = endYear - startYear + 1; // +1 to include both start and end years
  const years = Array.from({ length: numYears }, (_, i) => startYear + i);

  return (
    <View style={styles.checkBoxGrid}>
      {years.map((year) => (
        <View key={year} style={{ alignItems: 'center', minWidth: styles.checkBoxItem.minWidth }}>
          <Text style={styles.checkBoxItem}>{year}</Text>
          <View style={styles.checkBox}>
            {year <= lastUpdatedYear && <View style={styles.checkedBox} />}
          </View>
        </View>
      ))}
    </View>
  );
};

const FamilyStatusRow = ({ status, isChecked }) => (
  <View style={styles.tableRow}>
    <Text style={styles.tableCell}>{status}</Text>
    <View style={[styles.tableCell, { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
      <CheckBox checked={isChecked} />
    </View>
  </View>
);

// Composant pour afficher la situation familiale et le droit d'entrée
const FamilyStatusTable = ({ maritalStatus }) => {
  // Statuts possibles
  const statuses = [
    { key: 'married', label: 'Marié' },
    { key: 'single', label: 'Célibataire' },
    { key: 'divorcedWithChildren', label: 'Divorcé avec enfants' },
    { key: 'divorcedWithoutChildren', label: 'Divorcé sans enfants' },
    { key: 'widowed', label: 'Veuve/Veuf' },
  ];

  return (
    <View style={styles.table}>
      {statuses.map((status) => (
        <View key={status.key} style={styles.tableRow}>
          <Text style={styles.tableCell}>{status.label}</Text>
          <View style={[styles.tableCell, { justifyContent: 'center', flexDirection: 'row' }]}>
            <CheckBox checked={maritalStatus === status.key} />
          </View>
        </View>
      ))}
    </View>
  );
};

const AdherentPDF = ({ adherent, children }) => {
  const lastUpdatedYear = new Date(adherent.contributionUpdatedAt).getFullYear();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.heading}>L'association El Mektoub</Text>
          <Text style={styles.heading}>Bulletin d'adhésion</Text>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.label}>NOM ET PRENOM</Text>
          <Text style={styles.field}>{adherent.mrName}</Text>

          <Text style={styles.label}>NOM ET PRENOM</Text>
          <Text style={styles.field}>{adherent.mrsName}</Text>

          <View style={styles.tableRow}>
            <Text style={[styles.label, { flex: 1 }]}>DATE DE NAISSANCE</Text>
            <Text style={[styles.field, { flex: 2 }]}>
          {formatDate(adherent.birthDate)} 
        </Text>
          </View>

          <Text style={styles.label}>ADRESSE</Text>
          <Text style={styles.field}>{adherent.address}</Text>

          <View style={styles.tableRow}>
            <Text style={[styles.label, { flex: 1 }]}>Téléphone</Text>
            <Text style={[styles.field, { flex: 2 }]}>{adherent.phone}</Text>
          </View>

          <Text style={styles.label}>Adresse mail</Text>
          <Text style={styles.field}>{adherent.email}</Text>

          <Text style={styles.label}>N° de sécurité sociale</Text>
          <Text style={styles.field}>{adherent.socialSecurityNumber}</Text>

          <Text style={styles.label}>Nationalité</Text>
          <Text style={styles.field}>{adherent.nationality}</Text>

          <Text style={styles.label}>Lieu d'inhumation</Text>
          <Text style={styles.field}>{adherent.burialSite}</Text>
        </View>

        <View style={styles.table}>
        <FamilyStatusTable maritalStatus={adherent.maritalStatus} />
          <Text style={styles.label}>
          Un enfant ayant eu 25 ans dans l'année en cours cotise 25 euros l'année

          </Text>
          <Text style={styles.label}>
          Un enfant se mariant avant 25 ans cotise 50€ l'année
          </Text>
          <Text style={styles.label}>
            Pour un enfant qui a 26 ans dans l'année en cours doit cotisé le retard de 55 euros
          </Text>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.label}>NOM DES ENFANTS ET DATE DE NAISSANCE</Text>
          {children.map((child, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.field, { flex: 2 }]}>{child.name}</Text>
              <Text style={[styles.field, { flex: 2 }]}>{formatDate(child.birthDate)}</Text>
            </View>
          ))}
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.label}>je m'engage à respecter le règlement</Text>
          <Text style={styles.label}>la cotisation annuel du 1 janvier au 28 février</Text>
          <Text style={styles.label}>nom et téléphone du délégué :{adherent.delegateName}</Text>
        </View>

        <View style={{ marginTop: 20, alignItems: 'flex-end' }}>
          <Text style={styles.label}>
            FAIT à.........................LE.........................
          </Text>
        </View>
        <View style={{ marginTop: 20 }}>
        <CheckBoxTable numYears={20} lastUpdatedYear={lastUpdatedYear} />

        </View>
      </Page>
    </Document>
  );
};

export default AdherentPDF;
