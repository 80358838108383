import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Spinner,
  Button,
  Select,
} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as xlsx from 'xlsx';

const ContributionTable = () => {
  const [contributions, setContributions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterYear, setFilterYear] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const PAGES_RANGE = 3;


  const paginateData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const exportToExcel = () => {
    const workbook = xlsx.utils.book_new();
  
    const data = contributions.map(contribution => ({
      'ID': contribution.id,
      'Adhérent': contribution.mrName,
      'Délégué': contribution.delegateName,
      'Année': contribution.annee,
      'Montant': contribution.montant,
      'Date de paiement': contribution.datePaiement ? formatDate(new Date(contribution.datePaiement)) : '', // Formater la date ici
    }));
  
    const worksheet = xlsx.utils.json_to_sheet(data);
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Cotisations');
  
    const currentYear = new Date().getFullYear();
    const fileName = `cotisations_${currentYear}.xlsx`;
  
    xlsx.writeFile(workbook, fileName);
  };
  
  // Fonction pour formater la date au format DD/MM/YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredContributions.length / itemsPerPage);
  
    if (totalPages <= 1) {
      return null; // Ne pas afficher la pagination s'il n'y a qu'une seule page
    }
  
    const pages = [];
    const startPage = Math.max(1, currentPage - PAGES_RANGE);
    const endPage = Math.min(totalPages, currentPage + PAGES_RANGE);
  
    // Ajouter les pages avant la plage actuelle
    if (startPage > 1) {
      pages.push(
        <Button key="start" onClick={() => handlePageChange(1)} colorScheme="gray" mx={1}>
          1
        </Button>,
        <span key="start-ellipsis" mx={1}>...</span>
      );
    }
  
    // Ajouter la plage de pages actuelle
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <Button
          key={page}
          onClick={() => handlePageChange(page)}
          colorScheme={currentPage === page ? 'teal' : 'gray'}
          mx={1}
        >
          {page}
        </Button>
      );
    }
  
    // Ajouter les pages après la plage actuelle
    if (endPage < totalPages) {
      pages.push(
        <span key="end-ellipsis" mx={1}>...</span>,
        <Button key="end" onClick={() => handlePageChange(totalPages)} colorScheme="gray" mx={1}>
          {totalPages}
        </Button>
      );
    }
  
    return <Flex justify="center" mt={4}>{pages}</Flex>;
  };


  useEffect(() => {
    const fetchContributions = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://app.falconmarketing.fr:3004/cotisations');
        const data = await response.json();

        if (data && data.contributions) {
          setContributions(data.contributions);
        }
      } catch (error) {
        console.error('Error fetching contributions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContributions();
  }, []);

  // Filtrer les contributions par année et date de paiement
  const filteredContributions = contributions.filter(contribution => {
    const yearMatch = !filterYear || contribution.annee === parseInt(filterYear);
    const dateMatch = !filterDate || new Date(contribution.datePaiement).toDateString() === filterDate.toDateString();
    return yearMatch && dateMatch;
  });

  // Mettre à jour les données paginées après le filtrage
  useEffect(() => {
    const paginatedData = paginateData(filteredContributions, currentPage, itemsPerPage);
    setPaginatedData(paginatedData);
  }, [filteredContributions, currentPage, itemsPerPage]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="200px">
        <Spinner size="xl" color="teal.500" />
      </Flex>
    );
  }

  return (
    <Box bg="gray.100" borderRadius="md" p={6}>
      <Heading size="md" mb={4} color="teal.600">
        Cotisations
      </Heading>
      <Flex mb={4} align="center">
        <Select
          mr={4}
          value={filterYear}
          onChange={e => setFilterYear(e.target.value)}
          placeholder="Filtrer par année"
        >
          <option value="">Toutes les années</option>
          {Array.from(new Set(contributions.map(contribution => contribution.annee))).map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
        <Box mr={4}>
          <DatePicker
            selected={filterDate}
            onChange={date => setFilterDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Filtrer par date de paiement"
            isClearable
          />
        </Box>
        <Button onClick={exportToExcel} bg="teal.500" color="white" _hover={{ bg: 'teal.600' }} borderRadius="md" boxShadow="md">
          Télécharger Excel
        </Button>
      </Flex>
      {paginatedData.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color="gray.600">Adhérent</Th>
              <Th color="gray.600">Délégué</Th>
              <Th color="gray.600">Année</Th>
              <Th color="gray.600">Montant</Th>
              <Th color="gray.600">Date de paiement</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData.map((contribution) => (
              <Tr key={contribution.id}>
                <Td>{contribution.mrName}</Td>
                <Td>{contribution.delegateName}</Td>
                <Td>{contribution.annee}</Td>
                <Td>{contribution.montant}</Td>
                <Td>{new Date(contribution.datePaiement).toLocaleDateString('fr-FR')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box textAlign="center">
          <Heading size="sm" color="gray.500">Aucune cotisation trouvée</Heading>
        </Box>
      )}
      {renderPagination()}
    </Box>
  );
};

export default ContributionTable;