import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  TableContainer,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Select,
} from '@chakra-ui/react';

const DecesList = () => {
  const [deces, setDeces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDeces, setSelectedDeces] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterYear, setFilterYear] = useState('');
  const [filteredDeces, setFilteredDeces] = useState([]);

  useEffect(() => {
    const sortedDeces = [...deces].sort(sortByDate);
    const sortedAndFilteredDeces = filterByYear(sortedDeces);
    setFilteredDeces(sortedAndFilteredDeces);
  }, [deces, sortOrder, filterYear]); // Dépendances à surveiller

  useEffect(() => {
    fetchDeces();
  }, []);


  const fetchDeces = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('https://app.falconmarketing.fr:3004/deces');
      if (response.data && Array.isArray(response.data.deces)) {
        setDeces(response.data.deces);
      } else {
        console.error('Erreur: la réponse du serveur n\'est pas un objet avec une propriété deces de type tableau');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des décès :', error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(deces);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Deces');
    XLSX.writeFile(workbook, 'deces.xlsx');
  };

  const handleViewDetails = (deces) => {
    setSelectedDeces(deces);
    onOpen();
  };

  // Fonction pour trier les décès par date
  const sortByDate = (a, b) => {
    const dateA = new Date(a.dateDecesLieu);
    const dateB = new Date(b.dateDecesLieu);
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  };

  // Fonction pour filtrer les décès par année
  const filterByYear = (deces) => {
    if (!filterYear) return deces;
    const year = parseInt(filterYear, 10);
    return deces.filter((deces) => {
      const decesDate = new Date(deces.dateDecesLieu);
      return decesDate.getFullYear() === year;
    });
  };

  // Gérer le changement d'ordre de tri
  const handleSortChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Gérer le changement de filtre par année
  const handleFilterChange = (event) => {
    setFilterYear(event.target.value);
  };

  // Obtenir les années uniques à partir des dates de décès
  const uniqueYears = [...new Set(deces.map((deces) => new Date(deces.dateDecesLieu).getFullYear()))].sort((a, b) => b - a);

  // Trier et filtrer les décès
  const sortedAndFilteredDeces = filterByYear(deces.sort(sortByDate));


   return (
    <Box marginTop={'100px'} backgroundColor="white" borderRadius="md" boxShadow="md" p={6}>
      <Heading as="h2" size="xl" mb={4} textAlign="center">
        Liste des décès
      </Heading>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box>
          <label htmlFor="filterYear">Filtrer par année :</label>
          <Select id="filterYear" value={filterYear} onChange={handleFilterChange} placeholder="Sélectionner une année">
            <option value="">Toutes les années</option>
            {uniqueYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Button onClick={handleSortChange} colorScheme="blue" mr={2}>
            Trier par date ({sortOrder === 'asc' ? 'Croissant' : 'Décroissant'})
          </Button>
          <Button onClick={exportToExcel} colorScheme="blue">
            Exporter en Excel
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Délégué</Th>
                <Th>Nom du défunt</Th>
                <Th>Date et lieu de décès</Th>
                <Th>Lieu d'enterrement</Th>
                <Th>Total</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
  {filteredDeces.map((deces) => (
    <Tr key={deces.id}>
      <Td>{deces.delegue}</Td>
      <Td>{deces.nomDefunt}</Td>
      <Td>{new Date(deces.dateDecesLieu).toLocaleDateString('fr-FR')}</Td>
      <Td>{deces.lieuEnterrement}</Td>
      <Td>{deces.total} €</Td>
      <Td>
        <Button colorScheme="blue" onClick={() => handleViewDetails(deces)}>
          Voir les détails
        </Button>
      </Td>
    </Tr>
  ))}
</Tbody>
          </Table>
        </TableContainer>
      )}

      {/* Modal pour afficher les détails d'un décès */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Détails du décès</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          {selectedDeces && (
  <Box>
    <Box mb={4}>
      <strong>Délégué:</strong> {selectedDeces.delegue}
    </Box>
    <Box mb={4}>
      <strong>Nom du défunt:</strong> {selectedDeces.nomDefunt}
    </Box>
    <Box mb={4}>
      <strong>Enfant:</strong> {selectedDeces.enfant ? 'Oui' : 'Non'}
    </Box>
    <Box mb={4}>
    <strong>Date et lieu de décès:</strong> {new Date(selectedDeces.dateDecesLieu).toLocaleDateString('fr-FR')}
    </Box>
                <Box mb={4}>
                  <strong>Lieu de décès:</strong> {selectedDeces.lieuDeces}
                </Box>
                <Box mb={4}>
                  <strong>Lieu de décès (autre):</strong> {selectedDeces.lieuDeces2}
                </Box>
                <Box mb={4}>
                  <strong>Lieu d'enterrement:</strong> {selectedDeces.lieuEnterrement}
                </Box>
                <Box mb={4}>
                  <strong>Funérarium:</strong> {selectedDeces.funerarium} €
                </Box>
                <Box mb={4}>
                  <strong>Toilette:</strong> {selectedDeces.toilette} €
                </Box>
                <Box mb={4}>
                  <strong>Pompes funèbres:</strong> {selectedDeces.pompesFunebres} €
                </Box>
                <Box mb={4}>
                  <strong>Billet d'accompagnement:</strong> {selectedDeces.billetAccompagnement} €
                </Box>
                <Box mb={4}>
                  <strong>Traitement:</strong> {selectedDeces.traitement} €
                </Box>
                <Box mb={4}>
                  <strong>Repas:</strong> {selectedDeces.repas} €
                </Box>
                <Box mb={4}>
                  <strong>Concession:</strong> {selectedDeces.concession} €
                </Box>
                <Box mb={4}>
                  <strong>Décès en Algérie:</strong> {selectedDeces.decesAlgerie} €
                </Box>
                <Box mb={4}>
                  <strong>Total:</strong> {selectedDeces.total} €
                </Box>
                <Box mb={4}>
                  <strong>Montant restant:</strong> {selectedDeces.montantRestant} €
                </Box>
                <Box mb={4}>
                  <strong>Observations:</strong> {selectedDeces.observations}
                </Box>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DecesList