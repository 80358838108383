import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Button, FormControl, FormLabel, Input, Select, VStack,
  HStack, IconButton, Box, useToast, AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Flex, Text
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdPersonAdd, MdUpload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';



const EditMemberModal = ({ isOpen, onClose, adherent, onSave, onDelete }) => {
  const initialMemberState = {
    mrName: '',
    mrsName: '',
    socialSecurityNumber: '',
    birthDate: '',
    address: '',
    phone: '',
    email: '',
    nationality: '',
    burialSite: '',
    maritalStatus: '',
    contributionStatus: '',
    delegateName: '',
  };

  const history = useHistory(); // This should be browser history to support state

  const handleCreateDeathRecord = (mrName, mrsName) => {
    // Navigate using browser history with state
    history.push('/admin/deces', { mrName, mrsName});
  };


  const [member, setMember] = useState(initialMemberState);
  const [children, setChildren] = useState([{ name: '', birthDate: '' }]);
  const [childrenToDelete, setChildrenToDelete] = useState([]);
  const toast = useToast();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [delegates, setDelegates] = useState([]);
  const onCloseAlert = () => setIsAlertOpen(false);
  const cancelRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  useEffect(() => {
    const fetchDelegates = async () => {
      const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
      if (response.ok) {
        const data = await response.json();
        setDelegates(data.delegateNames);
      }
    };

    fetchDelegates();
  }, []);

  const handleClose = () => {
    // Réinitialise l'état de l'alerte de confirmation
    setIsAlertOpen(false);

    // Appelle la fonction onClose originale passée en prop
    onClose();
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch('https://app.falconmarketing.fr:3004/adherents');
      if (response.ok) {
        const data = await response.json();
        // Do something with the data
      } else {
        throw new Error('Failed to fetch members');
      }
    } catch (error) {
      console.error('Failed to fetch members:', error);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsAlertOpen(false);
    }
  }, [isOpen]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name.startsWith('children')) {
      const [_, field, childIndexStr] = name.split('.');
      const childIndex = parseInt(childIndexStr, 10); // Conversion en nombre
      const updatedChildren = [...children];
      updatedChildren[childIndex] = { ...updatedChildren[childIndex], [field]: value };
      setChildren(updatedChildren);
    } else {
      setMember(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAddChild = () => {
    setChildren([...children, { name: '', birthDate: '' }]);
  };

  const handleRemoveChild = (index) => {
    const child = children[index];
    if (child.id) {
      // Ajoute l'ID de l'enfant à la liste des enfants à supprimer
      setChildrenToDelete(prev => [...prev, child.id]);
    }
    // Supprime l'enfant de l'état local, indépendamment de son enregistrement dans la base de données
    const updatedChildren = [...children];
    updatedChildren.splice(index, 1);
    setChildren(updatedChildren);
  };
  


  useEffect(() => {
    const fetchChildren = async () => {
      if (adherent && adherent.id && isOpen) {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/children`);
          const data = await response.json();
          setChildren(data.children.length > 0 ? data.children : [{ name: '', birthDate: '' }]);
        } catch (error) {
          console.error('Failed to fetch children:', error);
          setChildren([{ name: '', birthDate: '' }]);
        }
      }
    };

    fetchChildren();

    if (adherent) {
      setMember({ ...adherent, children: undefined }); // Ensure children is not duplicated in member state
    } else {
      setMember(initialMemberState);
    }
  }, [adherent, isOpen]);

  const handleSave = async () => {
    try {
      // Suppression des enfants
      await Promise.all(childrenToDelete.map(childId =>
        fetch(`https://app.falconmarketing.fr:3004/children/${childId}`, {
          method: 'DELETE',
        })
      ));
      

      // Réinitialise la liste des enfants à supprimer après la suppression réussie
      setChildrenToDelete([]);
      // Update the adherent information
      const adherentResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(member),
      });

      if (!adherentResponse.ok) {
        throw new Error('Failed to update adherent');
      }

      // Iterate over the children to update or add
      await Promise.all(children.map(async (child) => {
        const url = child.id ? `https://app.falconmarketing.fr:3004/children/${child.id}` : `https://app.falconmarketing.fr:3004/children/${adherent.id}`;
        const method = child.id ? 'PUT' : 'POST';

        const childResponse = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: child.name,
            birthDate: child.birthDate,
            adherentId: adherent.id, // This assumes POST requests require adherentId in the body
          }),
        });

        if (!childResponse.ok) {
          throw new Error('Failed to update or add child');
        }
      }));

      toast({
        title: 'Success',
        description: 'Adherent and children information updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose(); // Close the modal upon successful save
    } catch (error) {
      toast({
        title: 'Error',
        description: `An error occurred: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateContributionAmount = (maritalStatus) => {
    const singleStatus = ['single', 'widowed', 'divorcedWithoutChildren'];
    const marriedOrDivorcedWithChildren = ['married', 'divorcedWithChildren'];
  
    if (singleStatus.includes(maritalStatus)) {
      return 55;
    } else if (marriedOrDivorcedWithChildren.includes(maritalStatus)) {
      return 110;
    } else {
      return 0; // Ou un autre montant par défaut si nécessaire
    }
  };
  

  const handleDeleteAdherent = async () => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete adherent');
      }
      toast({
        title: 'Adhérent supprimé',
        description: 'L\'adhérent et ses enfants ont été supprimés avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false); // Ferme la modale de confirmation
      if (typeof onDelete === 'function') {
        onDelete(adherent.id); // Appelle la fonction onDelete si elle est définie
      }
      onClose(); // Ferme la modale d'édition
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false); // Ferme la modale de confirmation même en cas d'erreur
    }
  };

  const handleCreateAdherentFromChild = async (childId) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/children/${childId}/transfer`, {
        method: 'POST',
      });
  
      if (response.ok) {
        toast({
          title: 'Succès',
          description: 'L\'enfant a été transféré en tant qu\'adhérent avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Rafraîchir la liste des adhérents et des enfants après le transfert réussi
        fetchMembers();
      } else {
        toast({
          title: 'Erreur',
          description: 'Une erreur est survenue lors du transfert de l\'enfant en adhérent.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier les informations du membre</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Nom et Prénom:</FormLabel>
              <HStack>
                <Input name="mrName" onChange={handleChange} value={member.mrName} placeholder="Nom et prénom" />
                <Button colorScheme="red" onClick={() => handleCreateDeathRecord(member.mrName)}>Décès</Button>
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Nom et Prénom:</FormLabel>
              <HStack>
                <Input name="mrsName" onChange={handleChange} value={member.mrsName} placeholder="Nom et prénom" />
                <Button colorScheme="red" onClick={() => handleCreateDeathRecord(member.mrsName)}>Décès</Button>
              </HStack>
            </FormControl>

              <FormControl>
                <FormLabel>Numéro de sécurité sociale</FormLabel>
                <Input name="socialSecurityNumber" onChange={handleChange} value={member.socialSecurityNumber} placeholder="Numéro de sécurité sociale" />
              </FormControl>

              <FormControl>
                <FormLabel>Date de naissance</FormLabel>
                <Input type="date" name="birthDate" onChange={handleChange} value={member.birthDate} />
              </FormControl>

              <FormControl>
                <FormLabel>Adresse</FormLabel>
                <Input name="address" onChange={handleChange} value={member.address} placeholder="Adresse" />
              </FormControl>

              <FormControl>
                <FormLabel>Téléphone</FormLabel>
                <Input name="phone" onChange={handleChange} value={member.phone} placeholder="Téléphone" />
              </FormControl>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input name="email" onChange={handleChange} value={member.email} placeholder="Email" />
              </FormControl>

              <FormControl>
                <FormLabel>Nationalité</FormLabel>
                <Input name="nationality" onChange={handleChange} value={member.nationality} placeholder="Nationalité" />
              </FormControl>

              <FormControl>
                <FormLabel>Lieu d'inhumation</FormLabel>
                <Input name="burialSite" onChange={handleChange} value={member.burialSite} placeholder="Lieu d'inhumation" />
              </FormControl>

              <FormControl>
                <FormLabel>Situation familiale</FormLabel>
                <Select name="maritalStatus" onChange={handleChange} value={member.maritalStatus} placeholder="Sélectionnez la situation familiale">
                  <option value="married">Marié</option>
                  <option value="single">Célibataire</option>
                  <option value="divorcedWithChildren">Divorcé avec enfants</option>
                  <option value="divorcedWithoutChildren">Divorcé sans enfants</option>
                  <option value="widowed">Veuf/Veuve</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Nom du délégué</FormLabel>
                <Select name="delegateName" onChange={handleChange} value={member.delegateName} placeholder="Sélectionnez un délégué">
                  {delegates.map((delegate, index) => (
                    <option key={index} value={delegate}>
                      {delegate}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Box>
    <FormLabel>Enfants</FormLabel>
    {children.map((child, index) => (
      <HStack key={index} mb={2}>
        <Input name={`children.name.${index}`} placeholder="Nom de l'enfant" value={child.name} onChange={(e) => handleChange(e, index)} />
        <Input type="date" name={`children.birthDate.${index}`} placeholder="Date de naissance" value={child.birthDate} onChange={(e) => handleChange(e, index)} />
        <IconButton
          aria-label="Créer un adhérent"
          icon={<MdPersonAdd />}
          onClick={() => handleCreateAdherentFromChild(child.id)}
        />
        <IconButton aria-label="Supprimer l'enfant" icon={<MdDelete />} onClick={() => handleRemoveChild(index)} />
      </HStack>
    ))}
    <Button leftIcon={<MdAdd />} colorScheme="teal" onClick={handleAddChild}>
      Ajouter un enfant
    </Button>
  </Box>
              <FormControl>
                <FormLabel>Statut de cotisation</FormLabel>
                <Select
                  name="contributionStatus"
                  onChange={handleChange}
                  value={member.contributionStatus}
                  placeholder="Sélectionnez le statut de cotisation"
                >
                  <option value="À jour">À jour</option>
                  <option value="En retard">En retard</option>
                  <option value="Radié">Radié</option>
                </Select>
              </FormControl>
              <Box mt={4}>
                <FormLabel>Pièces justificatives</FormLabel>
                <Flex alignItems="center" mb={2}>
                  <IconButton
                    aria-label="Ajouter des fichiers"
                    icon={<MdUpload />}
                    onClick={() => fileInputRef.current.click()}
                  />
                  <input
                    type="file"
                    multiple
                    accept="application/pdf,image/jpeg,image/png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </Flex>
                {selectedFiles.length > 0 && (
                  <Flex flexWrap="wrap">
                    {selectedFiles.map((file, index) => (
                      <Box key={index} mr={2} mb={2}>
                        <Text>{file.name}</Text>
                      </Box>
                    ))}
                  </Flex>
                )}
              </Box>
              
            </VStack>

          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={() => setIsDeleteAlertOpen(true)}>
              Supprimer
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Sauvegarder
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer l'adhérent
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr ? Vous ne pouvez pas annuler cette action par la suite.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAdherent} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditMemberModal;