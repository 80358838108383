import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  useColorMode,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
  useDisclosure,
  IconButton,
  Heading,
  Select,
  HStack,
  Tooltip,
  Avatar,
  SimpleGrid,
  Stack,
  Checkbox,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import "views/admin/dataTables/components/pagination.css";
import { MdAdd, MdRemoveRedEye, MdEdit, MdDelete } from "react-icons/md";
import { Input } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import "views/admin/default/components/i18n.js";
import AddMemberModal from "./AddMemberModal";
import AdherentDetailsModal from "./AdherentDetailsModal";
import EditMemberModal from "./EditMemberModal";
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

const MemberList = () => {
  const [searchValue, setSearchValue] = useState("");
  const { saveMemberToFirestore } = useContext(AuthContext);
  const [members, setMembers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const bgColor = useColorModeValue("white", "gray.700");
  const { colorMode } = useColorMode();
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const membersPerPage = 10;
  const [selectedDelegate, setSelectedDelegate] = useState('');
  const [delegates, setDelegates] = useState([]);
  const [selectedContributionStatus, setSelectedContributionStatus] = useState('');
  const [isAddDelegateModalOpen, setIsAddDelegateModalOpen] = useState(false);
  const [editedContributionStatus, setEditedContributionStatus] = useState({});
  const [contributionStatusUpdatedAt, setContributionStatusUpdatedAt] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const uniqueDelegates = Array.from(new Set(delegates));
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('id'); // Default sort by id
  const [isLoading, setIsLoading] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const { isOpen: isDeleteDialogOpen, onOpen: onDeleteDialogOpen, onClose: onDeleteDialogClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [loadingMembers, setLoadingMembers] = useState({});

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const handleAllChecked = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);

    const newEditedContributionStatus = {};
    const newContributionStatusUpdatedAt = {};

    currentMembers.forEach((member) => {
      newEditedContributionStatus[member.id] = newAllChecked ? "À jour" : "En retard";
      newContributionStatusUpdatedAt[member.id] = new Date().toISOString();
    });

    setEditedContributionStatus(newEditedContributionStatus);
    setContributionStatusUpdatedAt(newContributionStatusUpdatedAt);
  };

  useEffect(() => {
    const fetchDelegates = async () => {
      try {
        const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
        const data = await response.json();
        setDelegates(data.delegateNames);
      } catch (error) {
        console.error('Error fetching delegates:', error);
      }
    };

    fetchDelegates();
  }, []);

  const fetchMembers = useCallback(async () => {
    try {
      let url = 'https://app.falconmarketing.fr:3004/adherents';

      const queryParams = [];
      if (selectedDelegate) {
        queryParams.push(`delegateName=${selectedDelegate}`);
      }
      if (selectedContributionStatus) {
        queryParams.push(`contributionStatus=${selectedContributionStatus}`);
      }
      queryParams.push(`sortOrder=${sortOrder}`);
      queryParams.push(`sortBy=${sortBy}`);
      if (searchValue) {
        queryParams.push(`search=${encodeURIComponent(searchValue)}`);
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
      }

      const response = await fetch(url);
      const data = await response.json();

      // Mise à jour en douceur des membres
      setMembers(prevMembers => {
        const updatedMembers = data.adherents.map(newMember => {
          const existingMember = prevMembers.find(m => m.id === newMember.id);
          return existingMember ? { ...existingMember, ...newMember } : newMember;
        });
        return updatedMembers;
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des adhérents:", error);
    }
  }, [selectedDelegate, selectedContributionStatus, sortOrder, sortBy, searchValue]);

  useEffect(() => {
    fetchMembers();
    const intervalId = setInterval(() => {
      fetchMembers();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [fetchMembers]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleOpenModal = (adherent) => {
    setSelectedAdherent(adherent);
    onOpen();
  };

  const handleSaveMember = async (memberData) => {
    await saveMemberToFirestore(memberData);
    setSelectedAdherent(null);
    handleCloseAddMemberModal();
  };

  const handleOpenAddMemberModal = () => setIsAddMemberModalOpen(true);
  const handleCloseAddMemberModal = () => setIsAddMemberModalOpen(false);

  const handleOpenEditMemberModal = (adherent) => {
    setSelectedAdherent(adherent);
    setIsEditMemberModalOpen(true);
  };

  const handleCheckboxChange = async (memberId, newValue) => {
    const member = currentMembers.find(m => m.id === memberId);
    if (!member) return;

    setLoadingMembers(prev => ({ ...prev, [memberId]: true }));

    try {
      const newEditedContributionStatus = {
        ...editedContributionStatus,
        [memberId]: newValue,
      };

      const allCheckedNow = currentMembers.every(
        (member) => newEditedContributionStatus[member.id] === "À jour"
      );

      setEditedContributionStatus(newEditedContributionStatus);
      setContributionStatusUpdatedAt({
        ...contributionStatusUpdatedAt,
        [memberId]: new Date().toISOString(),
      });
      setAllChecked(allCheckedNow);

      if (newValue === "À jour") {
        await checkExistingCotisation(memberId, member.cotisation);
      } else if (newValue === "En retard") {
        const currentYear = new Date().getFullYear();
        await deleteCotisationEntry(memberId, currentYear);
      }

      // Update the member's status in the database
      await updateMemberStatus(memberId, newValue);

      // Refresh only the changed member
      const updatedMember = await fetchMember(memberId);
      setMembers(prevMembers => prevMembers.map(m => m.id === memberId ? updatedMember : m));
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
    } finally {
      setLoadingMembers(prev => ({ ...prev, [memberId]: false }));
    }
  };

  const checkExistingCotisation = async (memberId, amount) => {
    try {
      const currentYear = new Date().getFullYear();
      const response = await fetch(`https://app.falconmarketing.fr:3004/cotisations/${memberId}?annee=${currentYear}`);
      
      if (response.ok) {
        const existingCotisation = await response.json();
        if (existingCotisation.length === 0) {
          // Aucune cotisation existante pour cette année, donc on l'ajoute
          addCotisation(memberId, amount);
        } else {
          console.log('Une cotisation existe déjà pour cette année.');
        }
      } else {
        throw new Error("Erreur lors de la vérification de la cotisation existante");
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de la cotisation existante:", error);
    }
  };

  const addCotisation = async (memberId, amount) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/cotisations/${memberId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          annee: new Date().getFullYear(),
          montant: amount,
          datePaiement: new Date().toISOString()
        }),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'ajout de la cotisation");
      }

      fetchMembers(); // Rafraîchir la liste des membres
    } catch (error) {
      console.error("Erreur lors de l'ajout de la cotisation:", error);
    }
  };

  const deleteCotisationEntry = async (memberId, annee) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/cotisations/${memberId}?annee=${annee}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        fetchMembers();
      } else {
        console.error('Erreur lors de la suppression de la cotisation');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la cotisation:', error);
    }
  };

  const updateCotisation = async (cotisationId, updatedData) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/cotisations/${cotisationId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
  
      if (response.ok) {
        console.log('Cotisation mise  jour avec succès');
      } else {
        console.error('Erreur lors de la mise à jour de la cotisation');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la cotisation:', error);
    }
  };

  const handleCotisationUpdate = (cotisationId, updatedAnnee, updatedMontant, updatedDatePaiement) => {
    const updatedData = {
      annee: updatedAnnee,
      montant: updatedMontant,
      datePaiement: updatedDatePaiement,
    };
  
    updateCotisation(cotisationId, updatedData);
  };

  const handleSaveContributionStatus = async () => {
    try {
      const updatedContributionStatusArray = Object.entries(editedContributionStatus);
      const updatedAtArray = Object.entries(contributionStatusUpdatedAt);
      const chunkedUpdatedContributionStatus = chunkArray(updatedContributionStatusArray, 10);
      const chunkedUpdatedAt = chunkArray(updatedAtArray, 10);
  
      for (let i = 0; i < chunkedUpdatedContributionStatus.length; i++) {
        const chunk = chunkedUpdatedContributionStatus[i];
        const updatedAtChunk = chunkedUpdatedAt[i];
        const updatedContributionStatusChunk = Object.fromEntries(chunk);
        const updatedAtChunkObj = Object.fromEntries(updatedAtChunk);
  
        const response = await fetch('https://app.falconmarketing.fr:3004/update-contribution-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ updatedContributionStatus: updatedContributionStatusChunk, updatedAt: updatedAtChunkObj }),
        });
  
        if (!response.ok) {
          console.error('Erreur lors de la mise à jour du statut de cotisation');
          break;
        }
      }
  
      setEditedContributionStatus({});
      setContributionStatusUpdatedAt({});
      fetchMembers();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut de cotisation:', error);
    }
  };

  useEffect(() => {
    if (Object.keys(editedContributionStatus).length > 0) {
      handleSaveContributionStatus();
    }
  }, [editedContributionStatus]);

  const isAnyContributionStatusEdited = Object.values(editedContributionStatus).some(
    (status) => status !== "À jour" && status !== "En retard"
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'À jour':
        return 'green.100';
      case 'Radié':
        return 'red.100';
      case 'En retard':
        return 'orange.100';
      default:
        return 'white';
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedMembers = useMemo(() => {
    let sortableItems = [...members];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [members, sortConfig]);

  const currentMembers = useMemo(() => {
    const indexOfLastMember = currentPage * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    return sortedMembers.slice(indexOfFirstMember, indexOfLastMember);
  }, [currentPage, sortedMembers, membersPerPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = useMemo(() => {
    const pageNumbers = [];
    const totalPages = Math.ceil(members.length / membersPerPage);

    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesToShow = 5;
      const currentPageGroup = Math.ceil(currentPage / maxPagesToShow);
      startPage = (currentPageGroup - 1) * maxPagesToShow + 1;
      endPage = startPage + maxPagesToShow - 1;
      if (endPage > totalPages) {
        endPage = totalPages;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }, [currentPage, members, membersPerPage]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const sortMembers = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy('mrName'); // Set sortBy to mrName when sorting by name
  };

  const handleDelete = (member) => {
    setMemberToDelete(member);
    onDeleteDialogOpen();
  };

  // New function to fetch a single member
  const fetchMember = async (memberId) => {
    const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${memberId}`);
    if (!response.ok) {
      throw new Error("Erreur lors de la récupération de l'adhérent");
    }
    return await response.json();
  };

  // New function to update member status in the database
  const updateMemberStatus = async (memberId, newStatus) => {
    const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${memberId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: newStatus }),
    });
    if (!response.ok) {
      throw new Error("Erreur lors de la mise à jour du statut de l'adhérent");
    }
  };

  return (
    <Box bg={bgColor} borderRadius="md" boxShadow="md" p={6} overflow="hidden" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading size="lg">Liste des adhérents</Heading>
      </Flex>

      <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={4}>
        <Box flex={{ base: "1 1 100%", md: "1 1 auto" }} minWidth={{ base: "100%", md: "300px" }}>
          <Text mb={2}>Recherche:</Text>
          <Input
            placeholder="Tapez pour rechercher..."
            value={searchValue}
            onChange={handleSearchChange}
            bg={colorMode === "dark" ? "gray.800" : "gray.100"}
            color={colorMode === "dark" ? "white" : "black"}
          />
        </Box>
        <Box flex={{ base: "1 1 100%", md: "1 1 auto" }} minWidth={{ base: "100%", md: "300px" }}>
          <Text mb={2}>Filtre par délégué:</Text>
          <Select
  placeholder="Sélectionnez un délégué"
  value={selectedDelegate}
  onChange={(e) => {
    setSelectedDelegate(e.target.value);
    setCurrentPage(1);
  }}
  bg={colorMode === "dark" ? "gray.800" : "gray.100"}
  color={colorMode === "dark" ? "white" : "black"}
>
  <option value="">Tous les délégués</option>
  {uniqueDelegates.sort((a, b) => a.localeCompare(b)).map((delegate) => (
    <option key={delegate} value={delegate}>
      {delegate}
    </option>
  ))}
</Select>

        </Box>
        <Box flex={{ base: "1 1 100%", md: "1 1 auto" }} minWidth={{ base: "100%", md: "300px" }}>
          <Text mb={2}>Filtre par statut de cotisation:</Text>
          <Select
            placeholder="Sélectionnez un statut"
            value={selectedContributionStatus}
            onChange={(e) => {
              setSelectedContributionStatus(e.target.value);
              setCurrentPage(1);
            }}
            bg={colorMode === "dark" ? "gray.800" : "gray.100"}
            color={colorMode === "dark" ? "white" : "black"}
          >
            <option value="">Tous les statuts</option>
            <option value="À jour">À jour</option>
            <option value="En retard">En retard</option>
            <option value="Radié">Radié</option>
          </Select>
        </Box>
      </Stack>

      <Button leftIcon={<MdAdd />} colorScheme="teal" variant="solid" onClick={handleOpenAddMemberModal} mb={4}>
        Ajouter un membre
      </Button>

      <Box overflowX="auto" width="100%">
        {isLoading ? (
          <Flex justify="center" align="center" height="200px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <MemberTable
            members={currentMembers}
            handleOpenModal={handleOpenModal}
            handleOpenEditMemberModal={handleOpenEditMemberModal}
            handleCheckboxChange={handleCheckboxChange}
            allChecked={allChecked}
            handleAllChecked={handleAllChecked}
            getStatusColor={getStatusColor}
            sortMembers={sortMembers}
            handleDelete={handleDelete}
            requestSort={requestSort}
            sortConfig={sortConfig}
            loadingMembers={loadingMembers}
          />
        )}
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} display={{ base: "grid", md: "none" }}>
        {currentMembers.map((member) => (
          <Box
            key={member.id}
            p={6}
            bg={getStatusColor(member.contributionStatus)}
            borderRadius="md"
            boxShadow="md"
            cursor="pointer"
            onClick={() => handleOpenModal(member)}
            _hover={{ boxShadow: "lg" }}
            transition="box-shadow 0.2s"
          >
            <Flex alignItems="center" mb={4}>
              <Avatar name={member.mrName} size="lg" mr={4} />
              <Text fontWeight="bold" fontSize="lg">
                {member.mrName}
              </Text>
            </Flex>
            <Text mb={2}>Adresse : {member.address}</Text>
            <Text mb={2}>Téléphone : {member.phone}</Text>
            <Text mb={2}>Numéro de Sécurité Sociale : {member.socialSecurityNumber}</Text>
            <Text mb={2}>Statut : {member.contributionStatus}</Text>
          </Box>
        ))}
      </SimpleGrid>

      <Flex justifyContent="center" mt={8}>
        <HStack spacing={2}>
          <Button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            variant="ghost"
            colorScheme="teal"
          >
            Précédent
          </Button>
          {pageNumbers.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => paginate(pageNumber)}
              variant={currentPage === pageNumber ? "solid" : "ghost"}
              colorScheme="teal"
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === Math.ceil(members.length / membersPerPage)}
            variant="ghost"
            colorScheme="teal"
          >
            Suivant
          </Button>
        </HStack>
      </Flex>

      <AddMemberModal
        isOpen={isAddMemberModalOpen}
        onClose={handleCloseAddMemberModal}
        onSave={handleSaveMember}
        selectedAdherent={selectedAdherent}
      />
      {selectedAdherent && (
        <AdherentDetailsModal isOpen={isOpen} onClose={onClose} adherent={selectedAdherent} />
      )}
      <EditMemberModal
        isOpen={isEditMemberModalOpen}
        onClose={() => setIsEditMemberModalOpen(false)}
        adherent={selectedAdherent}
        onSave={handleSaveMember}
      />

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirmer la suppression</AlertDialogHeader>
            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer ce membre ? Cette action est irréversible.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteDialogClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={() => {
                // Logique de suppression
                onDeleteDialogClose();
              }} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const MemberTable = React.memo(({ members, handleOpenModal, handleOpenEditMemberModal, handleCheckboxChange, allChecked, handleAllChecked, getStatusColor, sortMembers, handleDelete, requestSort, sortConfig, loadingMembers }) => {
  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? <TriangleUpIcon /> : <TriangleDownIcon />;
    }
    return null;
  };

  return (
    <Box display={{ base: "none", md: "block" }} width="100%">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th onClick={() => requestSort('id')} cursor="pointer">
              Numéro {getSortIcon('id')}
            </Th>
            <Th onClick={() => requestSort('mrName')} cursor="pointer">
              Nom et Prénom {getSortIcon('mrName')}
            </Th>
            <Th>Adresse</Th>
            <Th>Téléphone</Th>
            <Th>Numéro SS</Th>
            <Th>Délégué</Th>
            <Th>Cotisation</Th>
            <Th>Statut</Th>
            <Th>Actions</Th>
            <Th>
              <Checkbox
                isChecked={allChecked}
                onChange={handleAllChecked}
                colorScheme="teal"
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.map((member) => (
            <MemberRow
              key={member.id}
              member={member}
              handleOpenModal={handleOpenModal}
              handleOpenEditMemberModal={handleOpenEditMemberModal}
              handleCheckboxChange={handleCheckboxChange}
              getStatusColor={getStatusColor}
              handleDelete={handleDelete}
              loadingMembers={loadingMembers}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
});

const MemberRow = React.memo(({ member, handleOpenModal, handleOpenEditMemberModal, handleCheckboxChange, getStatusColor, handleDelete, loadingMembers }) => {
  return (
    <Tr bg={getStatusColor(member.contributionStatus)}>
      <Td>{member.id}</Td>
      <Td>{member.mrName}</Td>
      <Td>{member.address}</Td>
      <Td>{member.phone}</Td>
      <Td>{member.socialSecurityNumber}</Td>
      <Td>{member.delegateName}</Td>
      <Td>{member.cotisation}</Td>
      <Td>{member.contributionStatus}</Td>
      <Td>
        <HStack spacing={1}>
          <Tooltip label="Voir les détails">
            <IconButton
              aria-label="Voir les détails"
              icon={<MdRemoveRedEye />}
              variant="ghost"
              size="xs"
              onClick={() => handleOpenModal(member)}
            />
          </Tooltip>
          <Tooltip label="Modifier">
            <IconButton
              aria-label="Modifier"
              icon={<MdEdit />}
              variant="ghost"
              size="xs"
              onClick={() => handleOpenEditMemberModal(member)}
            />
          </Tooltip>
        </HStack>
      </Td>
      <Td>
        <Flex alignItems="center" justifyContent="center">
          {loadingMembers[member.id] ? (
            <Spinner size="sm" color="teal.500" />
          ) : (
            <Checkbox
              isChecked={member.contributionStatus === "À jour"}
              onChange={(e) =>
                handleCheckboxChange(
                  member.id,
                  e.target.checked ? "À jour" : "En retard"
                )
              }
              colorScheme="teal"
              size="sm"
            />
          )}
        </Flex>
      </Td>
    </Tr>
  );
});

export default MemberList;